import React, { Component } from 'react';
import { PulseLoader as LoadingSpinner } from 'react-spinners';

class Loading extends Component {

  render() {
      const primaryColor = this.props.primaryColor || "#000";
        return (
            <div style={{scrollbarWidth:"none", height: '100vh'}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                    <LoadingSpinner color={primaryColor} loading={true} />
                </div>
            </div>
        )
    }
}

export default Loading;
